import { PartDetail } from '@frontoffice/data-access/template';
import { TemplateArgument } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';
import { replaceArgumentsForString } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/part-arguments';

export class TextFieldPartDetail extends PartDetail {
    code: string;
    label: string;
    placeholder: string;
    processedLabel: string;
    processedPlaceholder: string;

    required: boolean;
    requiredMessage: string;
    processedRequiredMessage: string;

    help: boolean;
    helpInformation: string;
    processedHelpInformation: string;

    answer: string;
    processedAnswer: string;
    enabled: boolean;

    invalidMessage: string | null;
}

export async function replaceArguments(detail: TextFieldPartDetail, templateArguments: TemplateArgument[]): Promise<void> {
    detail.processedAnswer = await replaceArgumentsForString(detail.answer, templateArguments);
    detail.processedLabel = await replaceArgumentsForString(detail.label, templateArguments);
    detail.processedPlaceholder = await replaceArgumentsForString(detail.placeholder, templateArguments);
    detail.processedRequiredMessage = await replaceArgumentsForString(detail.requiredMessage, templateArguments);
    detail.processedHelpInformation = await replaceArgumentsForString(detail.helpInformation, templateArguments);
}
