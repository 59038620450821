import { PartDetail } from '@frontoffice/data-access/template';
import { TemplateArgument } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';
import { replaceArgumentsForString } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/part-arguments';
import { ImagePartDetail } from '../../image-part/model/image-part.detail';

export class ChipsPartDetail extends PartDetail {
    code: string;
    title: string;
    processedTitle: string;
    icon?: string;
    processedIcon: string;
}

export async function replaceArguments(detail: ChipsPartDetail, templateArguments: TemplateArgument[]): Promise<void> {
    detail.processedTitle = await replaceArgumentsForString(detail.title, templateArguments);
    detail.processedIcon = await replaceArgumentsForString(detail.icon, templateArguments);
}
