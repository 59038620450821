<div *ngIf="partDetail && formGroup" [formGroup]="formGroup" [id]="'id-' + part.id" class="part-content">
    <mat-form-field appearance="outline" data-name="form-code" subscriptSizing="dynamic">
        <mat-label *ngIf="partDetail.label">{{ partDetail.processedLabel }}</mat-label>
        <input
            id="input-{{ part.instanceIdentifier }}"
            matInput
            name="answer"
            align="end"
            [required]="partDetail.required"
            [placeholder]="partDetail.processedPlaceholder"
            formControlName="value" />
        <mat-error *ngIf="valueControl().touched && valueControl().invalid && partDetail.required && valueControl().hasError('required')">{{
            partDetail.processedRequiredMessage
        }}</mat-error>
        <mat-error *ngIf="valueControl().hasError('invalidmessage')">{{ partDetail.invalidMessage }}</mat-error>
        <mat-error
            *ngIf="valueControl().touched && valueControl().invalid && partDetail.validationRegex && valueControl().hasError('pattern')"
            >{{ partDetail.processedValidationMessage }}</mat-error
        >
        <mat-icon
            class="help-icon"
            color="primary"
            *ngIf="partDetail.help"
            matSuffix
            [showTrigger]="NgxFloatUiTriggers.hover"
            appendTo="body"
            [floatUi]="questionInformationPopover"
            >help
        </mat-icon>
        <float-ui-content #questionInformationPopover>
            <div class="popover">
                <div [innerHTML]="partDetail.processedHelpInformation"></div>
            </div>
        </float-ui-content>
    </mat-form-field>
</div>
