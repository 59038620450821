<div #grid class="subcontainer part-content" [id]="'id-' + part.id" (click)="onClick()" *ngIf="partDetail">
    <ng-container *ngFor="let verticalListPart of partDetail.parts; trackBy: identifyPart">
        <ng-container *ngIf="!verticalListPart.hidden">
            <verticallist-part
                class="part"
                [attr.id]="'part-' + verticalListPart.id"
                [templateVersion]="templateVersion"
                [application]="application"
                [partId]="verticalListPart.selectorId"
                [host]="host"
                [parentFormGroup]="parentFormGroup"
                [style.display]="verticalListPart.displayNone ? 'none' : verticalListPart.displayBlock ? 'block' : null"
                (executeAction)="executeAction.emit($event)">
            </verticallist-part>
        </ng-container>
    </ng-container>
</div>
