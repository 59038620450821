import { Injectable } from '@angular/core';
import { Method } from '../methods/method';
import { HideDialog } from '../methods/hidedialog';
import { Login } from '../methods/login';
import { LoginIdp } from '../methods/login-idp';
import { Logout } from '../methods/logout';
import { OpenNextStep } from '../methods/opennextstep';
import { OpenPreviousStep } from '../methods/openpreviousstep';
import { Route } from '../methods/route';
import { ShowDialog } from '../methods/showdialog';
import { ShowSnackbar } from '../methods/showsnackbar';
import { UpdatePassword } from '../methods/updatepassword';
import { DownloadMedia } from '../methods/download_media';
import { ResendVerifyEmailMail } from '../methods/resendVerifyEmailMail';
import { ResetPassword } from '../methods/resetPassword';
import { RouteToLoginPage } from '../methods/routeToLoginPage';
import { RouteToResetPasswordPage } from '../methods/routeToResetPasswordPage';
import { OpenStep } from '../methods/openstep';
import { RouteExternally } from '../methods/route-externally';
import { OpenMedia } from '../methods/open_media';
import { Streamresults } from '../methods/streamresults';
import { ScrollTo } from '../methods/scrollTo';
import { ConfigureOtp } from '../methods/configure_otp';
import { CheckOtp } from '../methods/check_otp';

@Injectable()
export class MethodProvider {
    methodMap: Map<string, Method> = new Map();

    constructor(
        private downloadMedia: DownloadMedia,
        private openMedia: OpenMedia,
        private hideDialog: HideDialog,
        private login: Login,
        private loginIdp: LoginIdp,
        private logout: Logout,
        private openNextStep: OpenNextStep,
        private openPreviousStep: OpenPreviousStep,
        private openStep: OpenStep,
        private route: Route,
        private routeExternally: RouteExternally,
        private showDialog: ShowDialog,
        private showSnackBar: ShowSnackbar,
        private updatePassword: UpdatePassword,
        private resendVerifyEmailMail: ResendVerifyEmailMail,
        private resetPassword: ResetPassword,
        private routeToLoginPage: RouteToLoginPage,
        private routeToResetPasswordPage: RouteToResetPasswordPage,
        private streamResult: Streamresults,
        private scrollTo: ScrollTo,
        private configureOtp: ConfigureOtp,
        private checkOtp: CheckOtp
    ) {
        this.methodMap.set('downloadmedia', downloadMedia);
        this.methodMap.set('openmedia', openMedia);
        this.methodMap.set('hidedialog', hideDialog);
        this.methodMap.set('login', login);
        this.methodMap.set('configureOtp', configureOtp);
        this.methodMap.set('login-idp', loginIdp);
        this.methodMap.set('logout', logout);
        this.methodMap.set('opennextstep', openNextStep);
        this.methodMap.set('openpreviousstep', openPreviousStep);
        this.methodMap.set('openstep', openStep);
        this.methodMap.set('route', route);
        this.methodMap.set('route-externally', routeExternally);
        this.methodMap.set('showdialog', showDialog);
        this.methodMap.set('showsnackbar', showSnackBar);
        this.methodMap.set('updatepassword', updatePassword);
        this.methodMap.set('resendVerifyEmailMail', resendVerifyEmailMail);
        this.methodMap.set('resetPassword', resetPassword);
        this.methodMap.set('routeToLoginPage', routeToLoginPage);
        this.methodMap.set('routeToResetPasswordPage', routeToResetPasswordPage);
        this.methodMap.set('streamresult', streamResult);
        this.methodMap.set('scrollto', scrollTo);
        this.methodMap.set('checkOtp', checkOtp);
    }

    public getMethod(key: string): Method | undefined {
        if (this.methodMap.has(key)) {
            return this.methodMap.get(key);
        } else {
            console.error('No method was found for key (new) ' + key);
            return undefined;
        }
    }
}
