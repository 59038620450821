<div *ngIf="options && uploadInput" [id]="'id-' + part.id" class="part-content file-uploader">
    <div *ngIf="this.files">
        <div *ngFor="let file of this.files" class="file">{{ file.name }}</div>
    </div>
    <div *ngIf="uploadBusy" class="uploadBusy" style="text-align: center">
        <video autoplay loop muted playsinline style="width: 150px; height: 150px">
            <source src="/src/images/theme/loading.webm" type="video/webm" />
        </video>
    </div>
    <div
        *ngIf="!uploadBusy && !uploadDone"
        class="drop-container"
        ngFileDrop
        [options]="options"
        (uploadOutput)="onUploadOutput($event)"
        [uploadInput]="uploadInput"
        [ngClass]="{
            'is-drop-over': dragOver,
            hidden: !this.partDetail.showDropContainer || this.files.length >= this.partDetail.maxUploads,
        }">
        <div style="margin-bottom: 5px">{{ partDetail.dragAndDropText }}</div>
        <div *ngIf="this.partDetail.showPickFileButton && this.files.length < this.partDetail.maxUploads">
            <label class="upload-button">
                <input
                    [id]="this.part.id + 'files'"
                    type="file"
                    ngFileSelect
                    [options]="options"
                    (uploadOutput)="onUploadOutput($event)"
                    [uploadInput]="uploadInput"
                    multiple />
                {{ partDetail.pickFileButtonText }}
            </label>
        </div>
    </div>

    <button
        *ngIf="
            !uploadBusy &&
            !uploadDone &&
            this.partDetail.showUploadButton &&
            this.files.length > 0 &&
            this.files.length <= this.partDetail.maxUploads
        "
        type="button"
        (click)="startUpload()"
        style="width: 100%"
        mat-button
        mat-raised-button>
        {{ partDetail.startUploadButtonText }}
    </button>
    <div *ngIf="uploadDone" class="uploadBusy" style="text-align: center; color: var(--success-color)">Upload was successful!</div>
    <button
        *ngIf="uploadDone"
        type="button"
        (click)="resetUpload()"
        style="width: 100%; background-color: var(--primary-color)"
        mat-button
        mat-raised-button>
        Upload more
    </button>
</div>
