import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MethodProvider } from './service/method.provider';
import { HideDialog } from './methods/hidedialog';
import { Login } from './methods/login';
import { LoginIdp } from './methods/login-idp';
import { Logout } from './methods/logout';
import { OpenNextStep } from './methods/opennextstep';
import { OpenPreviousStep } from './methods/openpreviousstep';
import { Route } from './methods/route';
import { ShowDialog } from './methods/showdialog';
import { ShowSnackbar } from './methods/showsnackbar';
import { UpdatePassword } from './methods/updatepassword';
import { DownloadMedia } from './methods/download_media';
import { FrontofficeDataAccessTemplateModule } from '@frontoffice/data-access/template';
import { RouteToLoginPage } from './methods/routeToLoginPage';
import { RouteToResetPasswordPage } from './methods/routeToResetPasswordPage';
import { ResendVerifyEmailMail } from './methods/resendVerifyEmailMail';
import { ResetPassword } from './methods/resetPassword';
import { OpenStep } from './methods/openstep';
import { RouteExternally } from './methods/route-externally';
import { OpenMedia } from './methods/open_media';
import { Streamresults } from './methods/streamresults';
import { ScrollTo } from './methods/scrollTo';
import { ConfigureOtp } from './methods/configure_otp';
import { CheckOtp } from './methods/check_otp';

@NgModule({
    imports: [CommonModule, FrontofficeDataAccessTemplateModule],
    providers: [
        DownloadMedia,
        OpenMedia,
        MethodProvider,
        HideDialog,
        Login,
        LoginIdp,
        ConfigureOtp,
        CheckOtp,
        Logout,
        OpenNextStep,
        OpenPreviousStep,
        OpenStep,
        Route,
        RouteExternally,
        ShowDialog,
        ShowSnackbar,
        UpdatePassword,
        RouteToLoginPage,
        RouteToResetPasswordPage,
        ResendVerifyEmailMail,
        ResetPassword,
        Streamresults,
        ScrollTo,
    ],
})
export class FrontofficeFeatureInterpretionModule {}
