<div *ngIf="partDetail && formGroup" [formGroup]="formGroup" [id]="'id-' + part.id" class="part-content">
    <mat-slide-toggle id="input-{{ part.instanceIdentifier }}" formControlName="value" [disabled]="!this.partDetail.enabled">
        {{ partDetail.processedLabel }}
        <mat-icon
            data-name="form-component-description"
            color="primary"
            class="help-icon"
            *ngIf="partDetail.help"
            [showTrigger]="NgxFloatUiTriggers.hover"
            appendTo="body"
            [floatUi]="questionInformationPopover"
            >help
        </mat-icon>
    </mat-slide-toggle>
    <float-ui-content #questionInformationPopover>
        <div class="popover">
            <div [innerHTML]="partDetail.processedHelpInformation"></div>
        </div>
    </float-ui-content>
</div>
