<img
    *ngIf="!partDetail.svg && partDetail.url && !partDetail.mediaId"
    #urlImageContainer
    [id]="'id-' + part.id"
    [matTooltip]="partDetail.processedTitle"
    class="part-content"
    (click)="executeOnClickAction()" />
<img
    *ngIf="
        !partDetail.svg &&
        (!partDetail.url || partDetail.url === '/images/theme/image_placeholder.svg') &&
        partDetail.mediaId &&
        !partDetail.automaticResizing
    "
    [id]="'id-' + part.id"
    [matTooltip]="partDetail.processedTitle"
    class="part-content"
    [src]="host + '/v2/media/file/' + partDetail.mediaId"
    (click)="executeOnClickAction()" />
<img
    *ngIf="
        !partDetail.svg &&
        (!partDetail.url || partDetail.url === '/images/theme/image_placeholder.svg') &&
        partDetail.mediaId &&
        partDetail.automaticResizing
    "
    [id]="'id-' + part.id"
    [matTooltip]="partDetail.processedTitle"
    class="part-content"
    (click)="executeOnClickAction()" />
<span
    *ngIf="partDetail.svg && (!partDetail.url || partDetail.url === '/images/theme/image_placeholder.svg') && !partDetail.mediaId"
    [id]="'id-' + part.id"
    class="svg-image"
    [matTooltip]="partDetail.processedTitle"
    (click)="executeOnClickAction()"
    #svgContainer>
</span>
