import { PartDetail } from '@frontoffice/data-access/template';
import { TemplateArgument } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';
import { replaceArgumentsForString } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/part-arguments';

export interface DropdownFieldPartDetail extends PartDetail {
    code: string;
    label: string;
    placeholder: string;
    processedLabel: string;
    processedPlaceholder: string;

    required: boolean;
    requiredMessage: string;
    processedRequiredMessage: string;

    help: boolean;
    helpInformation: string;
    processedHelpInformation: string;

    invalidMessage: string;

    answer: string;
    processedAnswer: string;

    choices: DropdownFieldPartChoice[];
    emptyChoice: boolean;
    enabled: boolean;
}

export async function replaceArguments(detail: DropdownFieldPartDetail, templateArguments: TemplateArgument[]): Promise<void> {
    detail.processedLabel = await replaceArgumentsForString(detail.label, templateArguments);
    detail.processedPlaceholder = await replaceArgumentsForString(detail.placeholder, templateArguments);
    detail.processedRequiredMessage = await replaceArgumentsForString(detail.requiredMessage, templateArguments);
    detail.processedHelpInformation = await replaceArgumentsForString(detail.helpInformation, templateArguments);
    detail.processedAnswer = await replaceArgumentsForString(detail.answer, templateArguments);
}

export interface DropdownFieldPartChoice {
    id?: string;
    code?: string;
    label?: string;

    help?: boolean;
    helpInformation?: string;
}
