<div *ngIf="partDetail && formGroup" [formGroup]="formGroup" [id]="'id-' + part.id" class="part-content">
    <mat-form-field appearance="outline" dataFormat-name="form-code" subscriptSizing="dynamic">
        <mat-label>{{ partDetail.processedLabel }}</mat-label>
        <input
            matInput
            id="input-{{ part.instanceIdentifier }}"
            type="url"
            name="answer"
            align="end"
            [required]="partDetail.required"
            formControlName="value" />
        <mat-error *ngIf="valueControl().touched && valueControl().invalid && valueControl().hasError('pattern')">{{
            partDetail.invalidUrlMessage
        }}</mat-error>
        <mat-error *ngIf="valueControl().touched && valueControl().invalid && partDetail.required && valueControl().hasError('required')">{{
            partDetail.processedRequiredMessage
        }}</mat-error>
        <mat-icon
            class="help-icon"
            color="primary"
            *ngIf="partDetail.help"
            matSuffix
            [showTrigger]="NgxFloatUiTriggers.hover"
            appendTo="body"
            [floatUi]="questionInformationPopover"
            >help
        </mat-icon>
        <float-ui-content #questionInformationPopover>
            <div class="popover">
                <div [innerHTML]="partDetail.processedHelpInformation"></div>
            </div>
        </float-ui-content>
    </mat-form-field>
</div>
