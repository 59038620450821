import { Injectable } from '@angular/core';
import { Method } from './method';
import { ExecutionScope } from '../../../../../data-access/template/src/lib/dto/interpretion/execution-scope';

declare var checkOtp: any;

@Injectable()
export class CheckOtp implements Method {
    execute(executionScope: ExecutionScope): void {
        checkOtp(executionScope.getRequiredVariable('otp'));
    }
}
