import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ComponentFront } from '../../../../../interface/component.front';
import { Part, PartDetail, TemplateVersion } from '@frontoffice/data-access/template';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NumberInputFieldPartDetail, replaceArguments } from '../../model/number-input-field-part.detail';
import { NumberInputFieldPartStyle } from '../../model/number-input-field-part.style';
import { ApplicationDto } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { PartActionLink } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { Subscription } from 'rxjs';
import { TemplateArgument } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';
import { FormField } from '../../../form-field/form-field.component';
import { NgxFloatUiTriggers } from 'ngx-float-ui';

@Component({
    selector: 'app-number-input-field-part-front',
    templateUrl: './number-input-field-part-front.component.html',
    standalone: false,
})
export class NumberInputFieldPartFrontComponent extends FormField implements ComponentFront, OnInit, OnChanges, OnDestroy {
    partDetail: NumberInputFieldPartDetail = null;
    partStyle: NumberInputFieldPartStyle = null;

    part: Part = null;

    templateVersion: TemplateVersion;

    application: ApplicationDto;

    executeAction: EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
    }>;

    parentFormGroup: FormGroup;

    subscriptions: Subscription = new Subscription();

    formGroup: FormGroup;

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        override fb: FormBuilder
    ) {
        super();
    }

    ngOnInit(): void {
        this.partDetail.processedAnswer = null;
        this.initialize();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.change();
        this.changeDetectorRef.detectChanges();

        // Keep this at != instead of !== because invalidMessage can be sometimes null or undefined;
        if (changes.part?.previousValue?.detail?.invalidMessage != changes.part?.currentValue?.detail?.invalidMessage) {
            this.valueControl()?.markAsTouched({ onlySelf: true });
            this.valueControl()?.updateValueAndValidity();
        }
    }

    ngOnDestroy(): void {
        this.destroy();
        this.subscriptions.unsubscribe();
    }

    executeOnChangeAction(): void {
        const templateActionArguments = this.templateVersion?.arguments?.map(templateArgument => {
            return {
                name: templateArgument?.name,
                value: templateArgument.value,
                calculatedValue: templateArgument.calculatedValue,
                subArguments: templateArgument.subArguments,
            };
        });
        const actionLinks: PartActionLink[] = Part.getActionLinkOfType(this.part, 'ON_CHANGE');
        if (!!actionLinks && actionLinks.length > 0) {
            this.executeAction.emit({
                trigger: this.part.id,
                actionLinks,
                arguments: templateActionArguments,
            });
        }
    }

    override getSubscriptions(): Subscription {
        return this.subscriptions;
    }

    override replaceArguments(): Promise<void> {
        return replaceArguments(this.partDetail, this.templateVersion.arguments);
    }

    override getValue(): any {
        return this.partDetail.processedAnswer;
    }

    override setValue(value: any): void {
        this.partDetail.answer = value;
        this.partDetail.processedAnswer = value;
    }

    override getPartDetail(): PartDetail {
        return this.partDetail;
    }

    override getCode(): string {
        return this.partDetail.code;
    }

    override getType(): string {
        return this.partDetail.partType;
    }

    override getIdentifier(): string | undefined {
        return this.part.instanceIdentifier;
    }

    override isRequired(): boolean {
        return this.partDetail.required;
    }

    override isEnabled(): boolean {
        return this.partDetail.enabled;
    }

    override getValidationRegex(): string | null {
        return null;
    }

    override getInvalidMessage(): string | null {
        return this.partDetail.invalidMessage;
    }

    override setInvalidMessage(invalidMessage: string | null): void {
        this.partDetail.invalidMessage = invalidMessage;
    }

    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
}
