<div *ngIf="partDetail && formGroup" [formGroup]="formGroup" class="part-container part-content" [id]="'id-' + part.id">
    <mat-form-field appearance="outline" dataFormat-name="form-code" subscriptSizing="dynamic" subscriptSizing="dynamic">
        <mat-label *ngIf="partDetail.label">{{ partDetail.processedLabel }}</mat-label>
        <textarea
            id="input-{{ part.instanceIdentifier }}"
            matInput
            formControlName="value"
            align="end"
            [required]="partDetail.required"
            (keyup.enter)="$event.stopPropagation()"
            [style.resize]="'none'"
            [style.padding]="0"
            [placeholder]="partDetail.processedPlaceholder"></textarea>
        <mat-error *ngIf="partDetail.required && valueControl().touched && valueControl().invalid && valueControl().hasError('required')"
            >{{ partDetail.processedRequiredMessage }}
        </mat-error>
        <mat-error *ngIf="valueControl().hasError('invalidmessage')">{{ partDetail.invalidMessage }}</mat-error>
        <mat-icon
            class="help-icon"
            color="primary"
            *ngIf="partDetail.help"
            matSuffix
            [showTrigger]="NgxFloatUiTriggers.hover"
            appendTo="body"
            [floatUi]="questionInformationPopover"
            >help
        </mat-icon>
        <float-ui-content #questionInformationPopover>
            <div class="popover">
                <div [innerHTML]="partDetail.processedHelpInformation"></div>
            </div>
        </float-ui-content>
    </mat-form-field>
</div>
