import { ChangeDetectorRef, Component, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ComponentFront } from '../../../../../interface/component.front';
import { Part, TemplateVersion } from '@frontoffice/data-access/template';
import { LineChartPartDetail } from '../../model/line-chart-part.detail';
import { LineChartPartStyle } from '../../model/line-chart-part.style';
import { ChartTitleOptions } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/dto/chart-title-options.interface';
import { ChartLegendOptions } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/dto/chart-legend-options.interface';
import { ApplicationDto } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { FormGroup } from '@angular/forms';
import { PartActionLink } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { TemplateArgument } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';
import { ColorV2 } from '@shared/data-access';

@Component({
    selector: 'app-line-chart-part-front',
    templateUrl: './line-chart-part-front.component.html',
    styleUrls: ['./line-chart-part-front.component.scss'],
    standalone: false,
})
export class LineChartPartFrontComponent implements ComponentFront, OnInit, OnChanges {
    partDetail: LineChartPartDetail;
    partStyle: LineChartPartStyle;

    templateVersion: TemplateVersion;

    application: ApplicationDto;

    executeAction: EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
    }>;

    parentFormGroup: FormGroup;

    get part(): Part {
        return this._part;
    }

    set part(value: Part) {
        this._part = value;
    }

    _part: Part;

    partUpdated: EventEmitter<{ part: Part }> = null;

    options: any;

    chartInstance: any;

    constructor(public changeDetectorRef: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges): void {
        setTimeout(() => {
            this._handleDetailChanged();
        }, 200);
    }

    ngOnInit(): void {}

    onChartInit(e: any): void {
        this.chartInstance = e;
        setTimeout(() => {
            this._handleDetailChanged();
        }, 200);
    }

    private _handleDetailChanged(): void {
        if (!!this._part) {
            const cssStyles = getComputedStyle(document.body);
            const partDetail = this._part.detail as LineChartPartDetail;
            const partStyle = this._part.style as LineChartPartStyle;

            const axisColor = this.resolveColorV2(this.partStyle?.axisLabelColor, cssStyles);

            const titleStyle = this.resolveTitleStyle(cssStyles);

            const titleOptions: ChartTitleOptions = {
                text: partDetail?.title,
                textStyle: {
                    color: titleStyle.color,
                    fontStyle: titleStyle.fontStyle,
                    fontWeight: titleStyle.fontWeight,
                    fontFamily: titleStyle.fontFamily,
                    fontSize: titleStyle.fontSize,
                },
            };

            const { xaxis: axisInfo } = partDetail;
            const xAxis = {
                name: axisInfo?.name,
                data: axisInfo?.points?.split(';').map(point => Number(point)) ?? [],
                silent: false,
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: axisColor,
                    },
                },
                axisLine: {
                    lineStyle: {
                        color: axisColor,
                    },
                },
            };

            const mapping =
                partDetail?.datasources?.map(sourceDetail => {
                    const sourceStyle = partStyle?.series.find(x => x.name === sourceDetail.name);
                    return {
                        detail: sourceDetail,
                        style: sourceStyle,
                    };
                }) ?? [];

            const series =
                mapping.map(source => {
                    const { detail, style } = source;
                    return {
                        id: detail.id,
                        name: detail?.name,
                        type: 'line',
                        data: detail?.points?.split(';').map(point => Number(point)),
                        smooth: style?.itemStyle?.smooth,
                        lineStyle: {
                            color: this.resolveColorV2(style?.itemStyle?.colorV2, cssStyles),
                            width: style?.itemStyle?.lineStyleWidth,
                            type: style?.itemStyle?.lineStyleType,
                        },
                        itemStyle: { color: this.resolveColorV2(style?.itemStyle?.colorV2, cssStyles) },
                        areaStyle: {
                            color: this.resolveColorV2(style?.itemStyle?.areaColorV2, cssStyles),
                            opacity: style?.itemStyle?.areaTransparency,
                        },
                    };
                }) ?? [];
            const { legend } = partStyle;

            const legendOptions = new ChartLegendOptions({
                show: legend?.show,
                orient: legend?.orient,
                left: legend?.left,
                top: legend?.top,
                data: mapping.map(source => ({
                    name: source.detail.name,
                    itemStyle: { color: this.resolveColorV2(source.style?.itemStyle.colorV2, cssStyles) },
                    textStyle: { color: this.resolveColorV2(source.style?.itemStyle.colorV2, cssStyles) },
                })),
                align: 'right',
            });

            this.options = {
                ...this.options,
                animation: partStyle.animation,
                animationDuration: partStyle.animationDuration,
                animationDurationUpdate: this.partStyle.animationDuration,
                xAxis,
                yAxis: {
                    splitLine: {
                        lineStyle: {
                            color: axisColor,
                        },
                    },
                    axisLine: {
                        lineStyle: {
                            color: axisColor,
                        },
                    },
                },
                series,
                title: titleOptions,
                legend: legendOptions,
                tooltip: {
                    trigger: 'item',
                },
                grid: {
                    left: partStyle.gridMarginStyle?.marginLeftStyle?.margin
                        ? partStyle.gridMarginStyle?.marginLeftStyle?.margin
                        : partStyle.gridMarginStyle?.marginStyle?.margin,
                    right: partStyle.gridMarginStyle?.marginRightStyle?.margin
                        ? partStyle.gridMarginStyle?.marginRightStyle?.margin
                        : partStyle.gridMarginStyle?.marginStyle?.margin,
                    bottom: partStyle.gridMarginStyle?.marginBottomStyle?.margin
                        ? partStyle.gridMarginStyle?.marginBottomStyle?.margin
                        : partStyle.gridMarginStyle?.marginStyle?.margin,
                    top: partStyle.gridMarginStyle?.marginTopStyle?.margin
                        ? partStyle.gridMarginStyle?.marginTopStyle?.margin
                        : partStyle.gridMarginStyle?.marginStyle?.margin,
                },
            };
            if (this.chartInstance) {
                this.chartInstance.resize({
                    animation: {
                        enabled: partStyle.animation,
                        duration: partStyle.animationDuration,
                    },
                });
                this.chartInstance.setOption(this.options, { notMerge: false });
            }
            this.changeDetectorRef.detectChanges();
        } else {
            this.options = {};
        }
    }

    private resolveColorV2(color: ColorV2 | undefined, style: CSSStyleDeclaration): string {
        if (!color) {
            return '#ffffff';
        }

        if (color.hex) {
            return color.hex.value;
        } else {
            return style.getPropertyValue(color.reference?.variable.name ?? '');
        }
    }

    private resolveTitleStyle(style: CSSStyleDeclaration): {
        color: string;
        fontStyle: string;
        fontWeight: string;
        fontFamily: string;
        fontSize: number;
    } {
        return {
            color: style.getPropertyValue('--headline-medium-font-color') ?? '#ffffff',
            fontStyle: style.getPropertyValue('--headline-medium-font-style') ?? 'normal',
            fontWeight: style.getPropertyValue('--headline-medium-font-weight') ?? 'bolder',
            fontFamily: style.getPropertyValue('--headline-medium-font-family') ?? 'sans-serif',
            fontSize: style.getPropertyValue('--headline-medium-font-size')
                ? +style.getPropertyValue('--headline-medium-font-size').replace('px', '').replace('rem', '')
                : 18,
        };
    }
}
