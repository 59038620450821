import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, from, fromEvent, of, Subscription } from 'rxjs';
import { debounceTime, filter, switchMap, take, tap } from 'rxjs/operators';
import { createInvalidMessageValidator, PartDetail } from '@frontoffice/data-access/template';
import { frontofficeEnvironment } from '@shared/environment';

export abstract class FormField {
    formGroup: FormGroup;
    parentFormGroup: FormGroup;
    fb: FormBuilder;
    private userIsTyping = new BehaviorSubject<boolean>(false);

    valueControl(): AbstractControl {
        return this.formGroup?.controls['value'];
    }

    initialize(): void {
        this.initForm();
        this.getSubscriptions().add(
            from(this.replaceArguments())
                .pipe(take(1))
                .subscribe(() => {
                    this.valueControl().setValue(this.getValue(), {
                        emitEvent: false,
                        onlySelf: true,
                    });
                    this.formGroup.updateValueAndValidity();
                })
        );
        this.changeDisabled();
        setTimeout(() => {
            const inputElement = document.getElementById('input-' + this.getIdentifier());
            if (inputElement) {
                fromEvent(inputElement, 'blur').subscribe(() => {
                    this.handleUserTyping(false);
                });
            }
        });
    }

    change(): void {
        this.changeDisabled();
        this.getSubscriptions().add(
            from(this.replaceArguments())
                .pipe(
                    take(1),
                    filter(() => {
                        return !!this.valueControl() && !this.userIsTyping.value;
                    })
                )
                .subscribe(() => {
                    this.valueControl().setValue(this.getValue(), {
                        emitEvent: false,
                        onlySelf: false,
                    });
                    this.formGroup.updateValueAndValidity();
                })
        );
    }

    destroy(): void {
        this.parentFormGroup.removeControl(this.getIdentifier());
    }

    initForm(): void {
        this.formGroup = this.fb.group({
            code: [this.getCode()],
            value: [this.getValue(), [createInvalidMessageValidator(() => this.getPartDetail())]],
            type: [this.getType()],
        });
        this.parentFormGroup.addControl(this.getIdentifier(), this.formGroup);

        if (this.isRequired()) {
            this.valueControl()?.addValidators([Validators.required]);
        }

        if (this.getValidationRegex()) {
            this.valueControl()?.addValidators([Validators.pattern(this.getValidationRegex())]);
        }

        if (this.getInvalidMessage()) {
            this.valueControl()?.markAllAsTouched();
            this.parentFormGroup.updateValueAndValidity();
        }

        this.getSubscriptions().add(
            this.valueControl()
                ?.valueChanges.pipe(
                    // this really should be != not !== because null & undefined are not equal...
                    filter(value => this.getValue() != value),
                    tap(value => {
                        this.handleUserTyping(true);
                        this.setValue(value);
                        this.clearInvalidMessageError();
                    }),
                    debounceTime(frontofficeEnvironment.inputdebounce),
                    switchMap(() => of(null).pipe(tap(() => this.executeOnChangeAction()))),
                    tap(() => setTimeout(() => this.handleUserTyping(false), 2000))
                )
                .subscribe()
        );
    }

    handleUserTyping(isTyping: boolean): void {
        this.userIsTyping.next(isTyping);
    }

    changeDisabled(): void {
        if (this.isEnabled() && this.valueControl()?.disabled) {
            this.valueControl()?.enable();
        } else if (!this.isEnabled() && !this.valueControl()?.disabled) {
            this.valueControl()?.disable();
        }
    }

    clearInvalidMessageError(): void {
        this.setInvalidMessage(null);
        this.valueControl()?.setErrors({ invalidmessage: null });
        this.valueControl()?.updateValueAndValidity();
    }

    abstract getSubscriptions(): Subscription;

    abstract replaceArguments(): Promise<void>;

    abstract getValue(): any;

    abstract setValue(value: any): void;

    abstract getPartDetail(): PartDetail;

    abstract getCode(): string;

    abstract getType(): string;

    abstract getIdentifier(): string | undefined;

    abstract isRequired(): boolean;

    abstract isEnabled(): boolean;

    abstract getValidationRegex(): string | null;

    abstract getInvalidMessage(): string | null;

    abstract setInvalidMessage(invalidMessage: string | null): void;

    abstract executeOnChangeAction(): void;
}
