<mat-tab-group *ngIf="partDetail && tabsInitialized" [id]="'id-' + part.id" class="part-content" (selectedTabChange)="onTabOpened($event)">
    <mat-tab *ngFor="let tab of partDetail.tabs; trackBy: identifyTab">
        <ng-template mat-tab-label>
            <mat-icon *ngIf="tab.icon">{{ tab.icon }}</mat-icon>
            {{ tab.name }}
        </ng-template>
        <ng-template matTabContent>
            <div class="subcontainer" [id]="tab.code" #grid>
                <ng-container *ngFor="let child of tab.parts; trackBy: identifyPart">
                    <ng-container *ngIf="!child.hidden">
                        <tabs-part
                            [attr.id]="'part-' + child.id"
                            [part]="child"
                            [partContainer]="this.part"
                            [host]="host"
                            [templateVersion]="templateVersion"
                            [application]="application"
                            [parentFormGroup]="parentFormGroup"
                            [style.display]="child.displayNone ? 'none' : child.displayBlock ? 'block' : null"
                            (executeAction)="executeAction.emit($event)">
                        </tabs-part>
                    </ng-container>
                </ng-container>
            </div>
        </ng-template>
    </mat-tab>
</mat-tab-group>
